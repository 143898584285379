<template>
  <div class='m-1 mb-5 mt-3'>
    <b-card>
      <template v-slot:header>
        <h1>
          Minhas indicações
        </h1>
      </template>
      <b-row cols='12' class='w-100'>
        <base-table
          v-if='plansPurchasedByUserIndication && plansPurchasedByUserIndication.length > 0'
          :columns='columns'
          :data='plansPurchasedByUserIndication'
          :total-items='plansPurchasedByUserIndicationMeta.total'
          :start-items='plansPurchasedByUserIndicationMeta.skip + 1'
          :max-items='plansPurchasedByUserIndicationMeta.limit'
          @set-page='getPlansPurchasedByUserIndication'
        >
          <template v-slot:body-user='row'>
            {{ row.item.user && row.item.user.username ? `${row.item.user.username.substring(0, 15)}***********` : '' }}
          </template>

          <template v-slot:body-createdAt='row'>
            {{ row.item && row.item.createdAt && row.item.createdAt ? formatDate(row.item.createdAt) : '' }}
          </template>

          <template v-slot:body-lastPayment='row'>
            {{ row.item && row.item.lastPayment && row.item.lastPayment ? formatDate(row.item.lastPayment) : 'Sem registro' }}
          </template>
        </base-table>
        <b-col v-else >
          <h5 class='text-center'>Sem registros de compras por indicação</h5>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import format from 'date-fns/esm/format/index'

export default {
  name: 'Indications',
  data() {
    return {
      columns: [
        { key: 'user', value: 'E-mail' },
        { key: 'createdAt', value: 'Data da compra' },
        { key: 'lastPayment', value: 'Data do pagamento' },
      ]
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Indicaçao',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    try{
      this.handlePlansPurchasedByUserIndication({username: this.user.username})
    } catch (e) {

    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    ...mapGetters('userPlans', {
      plansPurchasedByUserIndication: 'plansPurchasedByUserIndication',
      plansPurchasedByUserIndicationMeta: 'plansPurchasedByUserIndicationMeta',
    }),
  },
  methods: {
    ...mapActions('userPlans', {
      handlePlansPurchasedByUserIndication: 'handlePlansPurchasedByUserIndication',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    async getPlansPurchasedByUserIndication(page){
       try {
         const pageCount = page <= 0 ? 1 : page
         const params = {
           skip: ((pageCount - 1) * this.plansPurchasedByUserIndicationMeta.limit),
           username: this.user.username
         }
         this.handlePlansPurchasedByUserIndication(params)
       } catch (e) {

       }
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
  }
}
</script>

<style lang='scss'>
</style>
